<template>
  <v-dialog v-model="dialog" persistent width="800">
    <v-card tile>
      <v-card-title class="lightGrey">
        <v-row>
          <v-col> {{ title }} </v-col>
          <v-col class="shrink">
            <v-btn icon @click="close()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-row dense v-if="!userSelected">
          <v-col>
            <v-card v-if="showContacts">
              <v-tabs active-class="msaBlue white--text">
                <v-tab>Users</v-tab>
                <v-tab-item>
                  <v-container>
                    <v-row>
                      <v-col>
                        <CustomizedAutoComplete
                          :value="selectedUser"
                          label="Select a user"
                          hide-details
                          :items="users"
                          item-text="fullname"
                          return-object
                          @change="
                            selectedUser = $event;
                            selectedContact = null;
                          "
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>

                <v-tab>Contacts</v-tab>
                <v-tab-item>
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-btn
                          class="msaBlue white--text"
                          @click="contactDetailsDialog = true"
                        >
                          Add contact
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <CustomizedAutoComplete
                          :value="selectedContact"
                          label="Select a contact"
                          hide-details
                          :items="contacts"
                          item-text="fullname"
                          return-object
                          @change="
                            selectedContact = $event;
                            selectedUser = null;
                          "
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>
              </v-tabs>
            </v-card>

            <CustomizedAutoComplete
              v-else
              :value="selectedUser"
              label="Select a user"
              hide-details
              :items="availableUsers"
              item-text="fullname"
              return-object
              @change="selectedUser = $event"
            />
          </v-col>
        </v-row>

        <v-row v-else no-gutters>
          <v-col cols="12"> Sign your signature below </v-col>
          <!-- the style is to prevent signature missing when scroll on a mobile device -->
          <v-col cols="12" align="center" :style="{ 'touch-action': 'none' }">
            <canvas
              ref="canvas"
              :style="{ width: '100%', height: '200px' }"
            ></canvas>
          </v-col>
          <v-col cols="12"> Or type in below </v-col>
          <v-col>
            <v-text-field
              v-model="typedSignature"
              dense
              maxlength="30"
              outlined
              background-color="white"
              clearable
              @keyup="typingSignature"
              @click:clear="clear()"
              data-testid="signature-name"
            ></v-text-field>
          </v-col>
          <v-col cols="12">Comment</v-col>
          <v-col cols="12">
            <v-textarea
              v-model="comment"
              maxlength="1000"
              outlined
              hide-details
              clearable
              dense
              background-color="white"
              rows="1"
            ></v-textarea>
          </v-col>
          <v-col v-if="$route.name == 'AssignedDocumentDetails'">
            By signing this document, you acknowledge that you have read and
            understood the document.
          </v-col>
        </v-row>

        <v-card-actions class="px-0">
          <v-btn text color="msaBlue" @click="clear()" v-if="userSelected">
            Clear
          </v-btn>
          <v-spacer v-if="userSelected"></v-spacer>
          <v-btn
            text
            color="msaBlue"
            class="mr-5"
            @click="
              clear();
              close();
            "
            v-if="userSelected"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green white--text"
            @click="save()"
            :disabled="disableSave"
            v-if="userSelected"
          >
            Save
          </v-btn>
          <v-spacer v-if="!userSelected"></v-spacer>
          <v-btn
            color="green white--text"
            @click="next()"
            :disabled="selectedUser == null && selectedContact == null"
            v-if="!userSelected"
          >
            next
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>

    <v-dialog v-model="contactDetailsDialog" width="550" persistent>
      <ContactDetails
        @close="
          contactDetailsDialog = false;
          reloadContactDetails += 1;
        "
        :key="reloadContactDetails"
        @getContacts="$emit('getContacts')"
        @onSave="selectedContact = $event"
      />
    </v-dialog>
  </v-dialog>
</template>

<script>
import SignaturePad from 'signature_pad';
import ContactDetails from '@/components/WAContacts/ContactDetails.vue';
export default {
  name: 'AddSignature',
  components: {
    ContactDetails,
  },
  props: {
    users: {
      type: Array,
    },
    contacts: {
      type: Array,
    },
    showContacts: {
      type: Boolean,
    },
    signatureTypeId: {
      type: Number,
    },
  },
  computed: {
    disableSave() {
      if (this.canvas === null || this.typedSignature === null) {
        return true;
      }

      if (!this.typedSignature.trim().length && this.canvas.isEmpty()) {
        return true;
      }

      return false;
    },
    availableUsers() {
      if (this.signatureTypeId == this.$constants.SIGNATURE_TYPES.SUPERVISOR) {
        return this.users.filter((u) => u.isSupervisor);
      }

      if (this.signatureTypeId == this.$constants.SIGNATURE_TYPES.MANAGER) {
        return this.users.filter((u) => u.isManager);
      }
      return this.users;
    },
    title() {
      switch (this.$route.name) {
        case 'AssignedDocumentDetails':
          return 'Sign Document';
        case 'FormDetails':
          return 'Sign Form';
        default:
          return '';
      }
    },
  },
  data() {
    return {
      dialog: false,
      comment: '',
      typedSignature: '',
      canvas: null,
      canvasOptions: {
        width: 750,
        height: 200,
        typedSignatureFontSize: 80,
      },
      selectedUser: null,
      selectedContact: null,
      userSelected: false,
      contactDetailsDialog: false,
      reloadContactDetails: 1,
    };
  },
  methods: {
    show(signatureTypeId) {
      if (this.$store.getters.isInternalAdmin) {
        this.selectedUser = {
          id: this.$store.getters.user.id,
          fullname:
            this.$store.getters.user.firstName +
            ' ' +
            this.$store.getters.user.lastName,
        };
      } else {
        const index = this.users.findIndex(
          (u) => u.id == this.$store.getters.user.id,
        );
        this.selectedUser = this.users[index];
      }

      this.$nextTick(() => {
        this.dialog = true;
        if (
          this.selectedUser &&
          signatureTypeId == this.$constants.SIGNATURE_TYPES.CREATED_BY
        ) {
          this.next();
        }
      });
    },
    async save() {
      const dataURL = this.canvas.toDataURL();
      const guid = await this.createGuid();
      let blob = this.dataURLToBlob(dataURL);
      this.$root.toggleLoaderText(true);
      try {
        blob = await this.$helpers.processFile(
          blob,
          this.$constants.ACCEPTED_MIME_TYPES.IMAGE,
        );
      } catch (error) {
        this.$root.toggleLoaderText(false);
        this.clear();
        if (error.message) {
          this.$root.showMessage(
            'Error',
            error.message,
            () => null,
            null,
            'ok',
          );
        }
        return;
      }
      this.$root.toggleLoaderText(false);
      this.$emit('signatureCreated', {
        guid: guid,
        binary: blob,
        type: this.$constants.BINARY_TYPES.FORM_SIGNATURE,
        fileType: blob.type,
        comment: this.comment ?? '',
        dataURL: dataURL,
        contactCompanyName:
          this.selectedContact == null ? '' : this.selectedContact.companyName,
        contactFullname:
          this.selectedContact == null ? '' : this.selectedContact.fullname,
        contactGuid:
          this.selectedContact == null ? '' : this.selectedContact.guid,
        signedByName:
          this.selectedUser == null ? '' : this.selectedUser.fullname,
        studentUserId: this.selectedUser == null ? '' : this.selectedUser.id,
        createdByName:
          this.$store.getters.user.firstName +
          ' ' +
          this.$store.getters.user.lastName,
      });
      this.close();
    },
    clear() {
      this.typedSignature = '';
      this.canvas.clear();
    },
    typingSignature() {
      const fontSize = 40;
      this.canvas.clear();
      const canvas = document.querySelector('canvas').getContext('2d');
      canvas.font = `${fontSize}px serif`;
      const signatureWidth = Math.round(
        canvas.measureText(this.typedSignature).width,
      );
      const startXPoint = (this.canvas.canvas.clientWidth - signatureWidth) / 2;
      const startYPoint = this.canvas.canvas.clientHeight - fontSize * 2;
      canvas.fillText(this.typedSignature, startXPoint, startYPoint);
    },
    dataURLToBlob(dataURL) {
      // Code taken from https://github.com/ebidel/filer.js
      var parts = dataURL.split(';base64,');
      var contentType = parts[0].split(':')[1];
      var raw = window.atob(parts[1]);
      var rawLength = raw.length;
      var uInt8Array = new Uint8Array(rawLength);

      for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }

      return new Blob([uInt8Array], { type: contentType });
    },
    onSign() {
      if (this.typedSignature === null) {
        this.typedSignature = '';
      }

      // Clear typed signature
      if (this.typedSignature.trim().length) {
        this.canvas.clear();
      }
      this.typedSignature = '';
    },
    // resize canvas for different screen sizes
    // so the typed and signed signature will display properly
    resizeCanvas() {
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      this.$refs.canvas.width = this.$refs.canvas.offsetWidth * ratio;
      this.$refs.canvas.height = this.$refs.canvas.offsetHeight * ratio;
      this.$refs.canvas.getContext('2d').scale(ratio, ratio);
      this.canvas.clear(); // otherwise isEmpty() might return incorrect value
    },
    init() {
      if (this.canvas == null) {
        // Need to wait for DOM load
        setTimeout(() => {
          this.canvas = new SignaturePad(this.$refs.canvas);
          this.canvas.addEventListener('beginStroke', this.onSign);
          window.onresize = this.resizeCanvas;
          this.resizeCanvas();
        }, 100);
      }
    },
    close() {
      this.dialog = false;
      this.$emit('reload');
    },
    next() {
      this.userSelected = true;
      this.init();
    },
    async createGuid() {
      const url = 'create-guid?format=json';
      return this.$axios
        .post(url, { loaderText: 'Loading...' })
        .then((response) => response.data);
    },
  },
};
</script>

<style scoped>
canvas {
  border: 1px black solid;
  border-radius: 5px;
}
</style>
